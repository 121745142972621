import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetCurrentOnboardingStep, SetOnboardingModal } from './onboarding.actions';

export class OnboardingStateModel {
  currentStep: number;
  modalName: string;
  showModal: boolean;
}

@State<OnboardingStateModel>({
  name: 'onboarding',
  defaults: {
    currentStep: 0, // Save the current step of the onboarding process in LocalStorage
    modalName: '',
    showModal: false,
  },
})
@Injectable()
export class OnboardingState {
  @Selector()
  static currentStep(state: OnboardingStateModel): number {
    return state.currentStep;
  }

  @Action(SetCurrentOnboardingStep)
  setCurrentOnboardingStep(
    ctx: StateContext<OnboardingStateModel>,
    { currentStep }: SetCurrentOnboardingStep,
  ) {
    ctx.patchState({
      currentStep,
    });
  }

  @Selector()
  static modalName(state: OnboardingStateModel): string {
    return state.modalName;
  }

  @Selector()
  static showModal(state: OnboardingStateModel): boolean {
    return state.showModal;
  }

  @Action(SetOnboardingModal)
  setOnboardingModal(
    ctx: StateContext<OnboardingStateModel>,
    { modalName, showModal }: SetOnboardingModal,
  ) {
    ctx.patchState({
      modalName,
      showModal,
    });
  }
}
