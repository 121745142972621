<rk-app-configuration-submenu
  [nomodal]="true"
  (submenuSelected)="onSelectSubmenu($event)"
></rk-app-configuration-submenu>
<rk-setting-divider></rk-setting-divider>
@if (selectedSubMenu === 'content') {
  <rk-menu-content></rk-menu-content>
}
@if (selectedSubMenu === 'design') {
  <rk-menu-design></rk-menu-design>
}
