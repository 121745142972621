import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { SetOnboardingModal } from '@app/core/states/onboarding.actions';
import { OnboardingState } from '@app/core/states/onboarding.state';
import { AppConfigurationService } from '@app/features/app-creation-and-configuration/services/app-configuration.service';
import {
  UpdateActivePage,
  UpdateActiveSettingsCategory,
} from '@app/features/app-creation-and-configuration/states/app-configuration.actions';
import { AppConfigurationState } from '@app/features/app-creation-and-configuration/states/app-configuration.state';
import { StepperState } from '@app/features/app-creation-and-configuration/states/stepper.state';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable, Subscription } from 'rxjs';

interface settingsItem {
  translationKey: string;
  value: string;
  icon: IconProp;
}

@Component({
  selector: 'rk-settings-category-list',
  templateUrl: './settings-category-list.component.html',
  styleUrls: ['./settings-category-list.component.scss'],
})
export class SettingsCategoryListComponent implements OnInit, OnDestroy {
  @Select(AppConfigurationState.activeSettingsCategory)
  activeSettingsCategory$: Observable<string>;

  @Select(OnboardingState.currentStep)
  onboardingCurrentStep$: Observable<number>;

  @Select(StepperState.isCreationProcess)
  isCreationProcess$: Observable<boolean>;

  @ViewChildren('settingsList') settingsListRef!: QueryList<ElementRef>;

  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly appConfigurationService: AppConfigurationService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([this.onboardingCurrentStep$, this.isCreationProcess$]).subscribe(
        ([step, isCreationProcess]) => {
          if (isCreationProcess) {
            if (step === 1) {
              this.store.dispatch(
                new SetOnboardingModal('ModalGeneralConfiguration', true),
              );
            } else if (step === 2) {
              this.store.dispatch(new SetOnboardingModal('ModalMenuManagement', true));
            } else if (step === 3) {
              this.store.dispatch(new SetOnboardingModal('ModalPagesManagement', true));
            }
          }
        },
      ),
    );
  }

  settingsList: settingsItem[] = [
    {
      translationKey: marker('settings-list.general-configuration'),
      value: 'general',
      icon: 'gears',
    },
    {
      translationKey: marker('settings-list.menu'),
      value: 'menu',
      icon: 'bars',
    },
    {
      translationKey: marker('settings-list.pages'),
      value: 'pages',
      icon: 'file-lines',
    },
  ];

  onSelectItem(value: string): void {
    switch (value) {
      case 'menu':
        this.appConfigurationService.openMenu();
        this.store.dispatch(new UpdateActiveSettingsCategory(value));
        this.store.dispatch(new UpdateActivePage(null));

        // event tracking
        this.store.dispatch(
          new SetEventProperties({
            name: 'NavMenu',
            component: 'Navigation',
          }),
        );
        this.store.dispatch(new LogEvent('Navigation Clicked'));
        break;
      case 'pages':
        this.store.dispatch(new UpdateActiveSettingsCategory(value));
        this.store.dispatch(new UpdateActivePage(0));

        // event tracking
        this.store.dispatch(
          new SetEventProperties({
            name: 'NavPagesPlayer',
            component: 'Navigation',
          }),
        );
        this.store.dispatch(new LogEvent('Navigation Clicked'));
        break;
      case 'general':
        this.store.dispatch(new UpdateActiveSettingsCategory(value));
        this.store.dispatch(new UpdateActivePage(null));

        // event tracking
        this.store.dispatch(
          new SetEventProperties({
            name: 'NavConfiguration',
            component: 'Navigation',
          }),
        );
        this.store.dispatch(new LogEvent('Navigation Clicked'));
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
