import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { SetOnboardingModal } from '@app/core/states/onboarding.actions';
import { capitalizeFirstLetter } from '@app/core/utils';
import { StepperState } from '@app/features/app-creation-and-configuration/states/stepper.state';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'rk-app-configuration-submenu',
  templateUrl: './app-configuration-submenu.component.html',
  styleUrls: ['./app-configuration-submenu.component.scss'],
})
export class AppConfigurationSubmenuComponent implements OnInit {
  selectedSubmenu = 'content';
  @ViewChild('highlightElement', { static: true }) highlightElementRef!: ElementRef;
  @Output() readonly submenuSelected = new EventEmitter<string>();
  @Input() nomodal?: boolean = false;
  hasVisited: string;
  private readonly subscription: Subscription = new Subscription();

  @Select(StepperState.isCreationProcess) isCreationProcess$: Observable<boolean>;

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.hasVisited = localStorage.getItem('hasSeenContentAndDesignModal');
    if (!this.hasVisited && !this.nomodal) {
      localStorage.setItem('hasSeenContentAndDesignModal', 'true');
    }
    this.subscription.add(
      combineLatest([this.isCreationProcess$]).subscribe(([isCreationProcess]) => {
        if (!this.nomodal && !this.hasVisited && isCreationProcess) {
          this.store.dispatch(new SetOnboardingModal('ModalContentAndDesign', true));
        }
      }),
    );
  }

  selectSubmenu(submenu: string): void {
    this.selectedSubmenu = submenu;
    this.submenuSelected.emit(this.selectedSubmenu);

    // event tracking
    this.store.dispatch(
      new SetEventProperties({
        name: 'Tab' + capitalizeFirstLetter(submenu),
        component: 'Tab',
      }),
    );
    this.store.dispatch(new LogEvent('Tab Clicked'));
  }
}
