<ng-container *ngIf="isLoading$ | async; else showContent">
  <div class="flex justify-center items-center w-full h-full">
    <mat-progress-spinner
      mode="indeterminate"
      [strokeWidth]="5"
      [diameter]="50"
    ></mat-progress-spinner>
  </div>
</ng-container>

<ng-template #showContent>
  @if (showAppManagerMobileWarning$ | async) {
    <!-- mobile placeholder -->
    <rk-mobile-access-warning [isAppManager]="true"></rk-mobile-access-warning>
  } @else {
    @if ((hasNotificationsEnabled$ | async) || (isAdminOrSuperuser$ | async)) {
      <div
        class="w-full h-full flex flex-col md:flex-row gap-8 notification-dashboard-container"
        [@fadeInOut]
      >
        <div class="w-full h-full flex flex-col">
          <div class="w-full md:mb-8">
            <!-- Header -->
            <div
              class="flex flex-col h-full notification-dashboard-title-container md:flex-row md:items-end md:justify-between p-6 md:p-0"
            >
              <div class="">
                <!-- Title -->
                <h1 class="notification-dashboard-title">
                  {{ 'notification-dashboard.title' | translate }}
                </h1>
                <div class="opacity-70 font-medium md:block hidden">
                  {{
                    'notification-dashboard.total'
                      | translate: { count: (totalNumber$ | async) }
                  }}
                </div>
              </div>
              <!-- Create notification button -->
              <div class="hidden md:block">
                <rk-button
                  *ngIf="
                    (isOpenFormButtonVisible$ | async) && (totalNumber$ | async) !== 0
                  "
                  [@quickFadeInOut]
                  class="h-full"
                  (buttonClick)="openForm('ButtonSendNotification')"
                  [text]="'notification-dashboard.send' | translate"
                >
                </rk-button>
              </div>
            </div>
          </div>
          <div
            *ngIf="(totalNumber$ | async) === 0"
            class="flex flex-col justify-center items-center w-full h-screen rounded-xl bg-white pb-4"
          >
            <img class="mb-8 max-w-[400px]" [src]="'upgrade-offer.image' | translate" />
            <h1 class="text-[24px] text-center font-bold max-w-[600px]">
              {{ 'notification-dashboard.total' | translate: { count: 0 } }}
            </h1>
            <p class="text-[16px] opacity-70 text-center max-w-[450px] mt-2">
              {{ 'notification-table.empty-state-desc' | translate }}
            </p>
            <rk-button
              [@quickFadeInOut]
              class="mt-7"
              (buttonClick)="openForm('ButtonSendNotificationEmptyState')"
              [text]="'notification-dashboard.send' | translate"
            ></rk-button>
          </div>
          <!-- Table container -->
          <rk-notification-table
            *ngIf="(totalNumber$ | async) !== 0"
          ></rk-notification-table>
        </div>
        <!-- Form container -->
        <rk-notification-form
          *ngIf="isFormVisible$ | async"
          class="h-full"
          [@slideInOut]
        ></rk-notification-form>
      </div>
    } @else {
      <div
        class="w-full h-full flex flex-col md:flex-row gap-8 notification-dashboard-container"
        [@fadeInOut]
      >
        <div class="w-full h-full flex flex-col">
          <div class="w-full md:mb-8">
            <!-- Header -->
            <div
              class="flex flex-col h-full notification-dashboard-title-container md:flex-row md:items-end md:justify-between p-6 md:p-0"
            >
              <div class="">
                <!-- Title -->
                <h1 class="notification-dashboard-title">
                  {{ 'notification-dashboard.title' | translate }}
                </h1>
                <div class="opacity-70 font-medium md:block hidden">
                  {{
                    'notification-dashboard.total'
                      | translate: { count: (totalNumber$ | async) }
                  }}
                </div>
              </div>
            </div>
          </div>
          <rk-upgrade-offer></rk-upgrade-offer>
        </div>
        <!-- Form container -->
      </div>
    }
  }
</ng-template>
