<div class="step-indicator flex bg-white rounded-full items-center">
  @for (_ of arr(); track $index) {
    <div
      class="dot size-1.5 rounded-full ml-3"
      [class.ml-1]="$index > 0"
      [class.active]="activeStep === $index"
      [class.cursor-pointer]="activeStep !== $index"
      (click)="activeStep !== $index && onStepClick($index)"
    ></div>
  }
</div>
