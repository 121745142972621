@if (showModal) {
  <svg class="overlay fixed top-0 left-0 w-[100vw] h-[100vh] z-[10000]">
    <defs>
      <mask id="mask">
        <rect width="100%" height="100%" fill="white"></rect>
        <rect #maskRect fill="black" rx="12"></rect>
      </mask>
    </defs>
    <rect class="background" width="100%" height="100%" mask="url(#mask)"></rect>
  </svg>

  <div
    #maskModal
    class="bg-white p-4 rounded-md fixed flex flex-col modal w-[400px] z-[10001]"
    [ngClass]="arrowPositionClass"
  >
    <div
      class="absolute flex top-0 right-0 size-[34px] bg-white items-center justify-center rounded-md cursor-pointer"
      (click)="onClose()"
    >
      <fa-icon icon="times"></fa-icon>
    </div>
    @if (image) {
      <img class="mb-6 pointer-events-none select-none" [src]="image" />
    }
    <h1 class="font-bold mb-2 select-none">
      {{ title }}
    </h1>
    <p class="opacity-70 mb-6 select-none">
      {{ desc }}
    </p>
    <div class="flex flex-row justify-between">
      <div>
        @if (previousButton) {
          <rk-button
            [text]="previousButton"
            [variant]="'secondary'"
            (buttonClick)="previous()"
            class="pr-3"
          ></rk-button>
        }
        <rk-button [text]="nextButton" (buttonClick)="next()"></rk-button>
      </div>
      @if (showStepper) {
        <rk-step-indicator
          class="items-center m-auto"
          [steps]="4"
          [activeStep]="onboardingCurrentStep - 1"
          [onStepClick]="goToStep.bind(this)"
        ></rk-step-indicator>
      }
    </div>
  </div>
}
