<div id="interface" class="size-full bg-white flex flex-col">
  <!-- language dropdown -->
  <div class="p-6">
    <div class="font-bold mb-4">
      {{ 'general-configuration.app-langugage' | translate }}
    </div>
    <div class="w-full">
      <rk-dropdown
        [options]="languageOptions"
        [selectedOption]="selectedLanguage"
        [fullWidth]="true"
        (selectionChange)="onLanguageSelectionChange($event)"
      >
      </rk-dropdown>
    </div>
  </div>
  <rk-setting-divider></rk-setting-divider>
  <!-- header color selector -->
  <rk-setting-container [name]="'general-configuration.header' | translate">
    <rk-color-selector
      [parameter]="'general-configuration.header-text-color' | translate"
      (colorChange)="onColorChange($event, 'nomColor')"
      [initialColor]="currentAppConfigHeaderTextColor$ | async"
    ></rk-color-selector>
  </rk-setting-container>
  <rk-setting-divider></rk-setting-divider>
  <!-- background configuration -->
  <rk-setting-container [name]="'general-configuration.styles-background' | translate">
    <!-- background type selection -->
    <mat-button-toggle-group
      [value]="backgroundType$ | async"
      aria-label="Background Type"
      (change)="onBackgroundTypeChange($event)"
    >
      <mat-button-toggle value="cover">{{
        'general-configuration.cover' | translate
      }}</mat-button-toggle>
      <mat-button-toggle value="image">{{
        'general-configuration.image' | translate
      }}</mat-button-toggle>
      <mat-button-toggle value="color">{{
        'general-configuration.color' | translate
      }}</mat-button-toggle>
    </mat-button-toggle-group>
    <!-- blur control -->
    <div class="mt-6" *ngIf="(backgroundType$ | async) !== 'color'">
      <div class="flex justify-between items-end">
        <div>{{ 'general-configuration.blur' | translate }}</div>
        <rk-switch
          [isChecked]="appBackgroundIsBlurred$ | async"
          (toggle)="onBlurToggle($event)"
        ></rk-switch>
      </div>
      <div class="opacity-70">{{ 'general-configuration.blur-details' | translate }}</div>
    </div>
    <!-- cover background -->
    <div class="opacity-70 mt-6" *ngIf="(backgroundType$ | async) === 'cover'">
      {{ 'general-configuration.cover-background-details' | translate }}
    </div>
    <!-- image background -->
    <div
      class="flex justify-between gap-6 mt-6"
      *ngIf="(backgroundType$ | async) === 'image'"
    >
      <div class="h-fit flex-1">
        <div class="mb-6 opacity-70">
          {{ 'general-configuration.default-background-image' | translate }}
        </div>
        <rk-image-upload
          #backgroundImageUploadComponent
          (fileSelected)="onBackgroundImageSelected($event)"
          [fetchedImageUrl]="currentAppConfigBackgroundImage$ | async"
          (imageRemoved)="onRemoveBackgroundImage('backgroundappfield')"
          [aspectRatio]="'1242/2208'"
          [radius]="40"
          [compactLayout]="true"
        ></rk-image-upload>
        <div class="mt-6">
          <rk-image-size-guide
            [width]="1242"
            [height]="2208"
            [lineBreakOption]="'both'"
          ></rk-image-size-guide>
        </div>
      </div>
      <div class="h-fit flex-1">
        <div class="mb-6 opacity-70">
          {{ 'general-configuration.long-background-image' | translate }}
        </div>
        <rk-image-upload
          #backgroundImageLongUploadComponent
          (fileSelected)="onBackgroundImageLongSelected($event)"
          [fetchedImageUrl]="currentAppConfigBackgroundImageLong$ | async"
          (imageRemoved)="onRemoveBackgroundImage('backgroundiphoneXappfield')"
          [aspectRatio]="'1242/2688'"
          [radius]="40"
          [compactLayout]="true"
        ></rk-image-upload>
        <div class="mt-6">
          <rk-image-size-guide
            [width]="1242"
            [height]="2688"
            [lineBreakOption]="'both'"
          ></rk-image-size-guide>
        </div>
      </div>
    </div>
    <!-- color background -->
    <div class="mt-6" *ngIf="(backgroundType$ | async) === 'color'">
      <rk-background-color-settings></rk-background-color-settings>
    </div>
  </rk-setting-container>
  <rk-setting-divider></rk-setting-divider>
  <!-- typography selector -->
  <rk-setting-container [name]="'general-configuration.typography' | translate">
    <rk-font-family-selector></rk-font-family-selector>
    <rk-font-size-selector></rk-font-size-selector>
  </rk-setting-container>
  <rk-setting-divider></rk-setting-divider>
  <!-- icons settings -->
  <rk-setting-container [name]="'general-configuration.icons' | translate">
    <rk-icon-style-selector></rk-icon-style-selector>
  </rk-setting-container>
  <rk-setting-divider></rk-setting-divider>
  <!-- styles settings -->
  <rk-setting-container [name]="'general-configuration.styles' | translate">
    <!-- border radius settings -->
    <rk-div-radius-settings></rk-div-radius-settings>
    <div class="mb-4"></div>
    <!-- shodow settings -->
    <rk-div-shadow-settings></rk-div-shadow-settings>
    <div class="mb-4"></div>
    <!-- text align settings -->
    <rk-text-align-settings></rk-text-align-settings>
    <div class="mb-4"></div>
    <!-- buttons color / main color selector -->
    <rk-button-style-settings></rk-button-style-settings>
    <div class="mb-4"></div>
    <!-- margin settings -->
    <rk-margin-settings></rk-margin-settings>
  </rk-setting-container>
  <rk-setting-divider></rk-setting-divider>
</div>
