<div class="flex flex-col flex-grow">
  <img src="/assets/clap.gif" />
  <div class="p-6">
    <div class="mb-8">
      <h1 class="text-2xl font-bold mb-3">
        {{ 'notification-congrats-dialog.title' | translate }}
      </h1>
      <p class="opacity-70">
        {{ 'notification-congrats-dialog.desc' | translate }}
      </p>
    </div>
    <div class="flex justify-end gap-2 md:gap-4 flex-col-reverse md:flex-row">
      <rk-button
        [text]="'notification-congrats-dialog.button' | translate"
        (buttonClick)="onDismiss()"
      ></rk-button>
    </div>
  </div>
</div>
