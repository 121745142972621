import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'rk-notification-congrats-dialog',
  templateUrl: './notification-congrats-dialog.component.html',
  styleUrl: './notification-congrats-dialog.component.scss',
})
export class NotificationCongratsDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NotificationCongratsDialogComponent>,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      new SetEventProperties({
        name: 'ModalCongrats',
        component: 'Modal',
      }),
    );
    this.store.dispatch(new LogEvent('Modal Viewed'));
  }

  onDismiss(): void {
    this.store.dispatch(
      new SetEventProperties({
        name: 'ButtonStartCongratsModal',
        component: 'Button',
      }),
    );
    this.store.dispatch(new LogEvent('Button Clicked'));

    this.dialogRef.close(false);
  }
}
