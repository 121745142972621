import { environment } from '@env/environment';
import { Component, HostBinding } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { LoginRequest } from '../../core/auth/states/auth.actions';

@Component({
  selector: 'rk-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  @HostBinding('class') classes = 'h-full flex';

  isLocal = !environment.production;
  loginForm: FormGroup;
  logForm: boolean;

  constructor(private readonly fb: FormBuilder) {
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  @Dispatch()
  dispatchLogin = (email: string, pwd: string) => new LoginRequest(email, pwd);

  login() {
    if (this.loginForm.valid) {
      this.dispatchLogin(this.loginForm.value.email, this.loginForm.value.password);
    }
  }

  autoLog() {
    this.dispatchLogin('jeremie@radioking.com', 'azerty');
  }

  autoLogClement() {
    this.dispatchLogin('clement@radioking.com', 'azerty');
  }
}
