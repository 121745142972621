<div
  id="stepper"
  class="stepper-container flex justify-between items-center w-full bg-brand-light px-6 py-4"
>
  <!-- previous step button -->
  <div>
    @if (isCreationProcess$ | async) {
      <rk-button
        variant="white"
        [text]="'stepper.previous' | translate"
        (buttonClick)="previous()"
        [class.invisible]="hidePreviousStep"
      ></rk-button>
    }
  </div>

  <!-- steps list -->
  <div class="flex justify-center items-center w-full mb-[20px]">
    @if (isCreationProcess$ | async) {
      <!-- step container -->
      <div
        *ngFor="let step of steps$ | async; let i = index"
        class="flex justify-center items-center"
      >
        <div class="flex flex-col items-center gap-1 step-container">
          <!-- done step -->
          <div *ngIf="step.status === 'done'" class="step">
            <div class="icon-and-number-container">
              <fa-icon [icon]="'check'" class="text-white text-xs ml-[1px]"></fa-icon>
            </div>
            <span class="step-label text-sm text-black">
              {{ step.translationKey | translate }}
            </span>
          </div>
          <!-- current step -->
          <div *ngIf="step.status === 'current'" class="step">
            <div class="icon-and-number-container">
              <div class="current-number-container text-xs font-bold step-number">
                {{ i + 1 }}
              </div>
            </div>
            <span class="step-label text-sm text-black font-bold">
              {{ step.translationKey | translate }}
            </span>
          </div>
          <!-- disabled step -->
          <div *ngIf="step.status === 'disabled'" class="step opacity-50">
            <div class="icon-and-number-container disabled-number-container">
              <div class="step-number text-black text-xs">
                {{ i + 1 }}
              </div>
            </div>
            <span class="step-label text-sm text-black">
              {{ step.translationKey | translate }}
            </span>
          </div>
        </div>
        <!-- separation line -->
        <div
          *ngIf="i < (stepsLength$ | async) - 1"
          class="separation-line w-[100px]"
          [ngClass]="{ 'separation-line-done': step.status === 'done' }"
        ></div>
      </div>
    }
  </div>
  <div class="flex items-center">
    <rk-api-error-alert></rk-api-error-alert>
    <!-- next step button -->
    @if (isCreationProcess$ | async) {
      <rk-button
        [text]="customNextStepText ? customNextStepText : ('stepper.next' | translate)"
        (buttonClick)="next()"
        [disabled]="(isLastStep$ | async) || nextStepIsDisabled || (isLoading$ | async)"
        rkTooltip
        [tooltipDescription]="tooltipMessage$ | async | translate"
        [tooltipDisabled]="(tooltipMessage$ | async) === null"
        [tooltipPosition]="'bottom'"
        [arrowPosition]="'left'"
        [customStyle]="tooltipFitContentStyle"
        [isLoading]="(isLoading$ | async) || (isLoadingAssets$ | async)"
      ></rk-button>
    } @else {
      <rk-button
        [text]="'stepper.save' | translate"
        (buttonClick)="save()"
        [disabled]="(isLastStep$ | async) || nextStepIsDisabled || (isLoading$ | async)"
        [isLoading]="isLoading$ | async"
      ></rk-button>
    }
  </div>
</div>
