<div class="p-6 flex text-black w-fit" id="app-configuration-submenu">
  <div
    class="w-[92px] cursor-pointer"
    (click)="selectSubmenu('content')"
    [class.active]="selectedSubmenu === 'content'"
  >
    {{ 'config-submenu.content' | translate }}
  </div>
  <div
    class="cursor-pointer"
    (click)="selectSubmenu('design')"
    [class.active]="selectedSubmenu === 'design'"
  >
    {{ 'config-submenu.design' | translate }}
  </div>
</div>
