import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { LogEvent, SetEventProperties } from '@app/core/states/event-tracking.actions';
import {
  SetCurrentOnboardingStep,
  SetOnboardingModal,
} from '@app/core/states/onboarding.actions';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'rk-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrl: './onboarding-modal.component.scss',
})
export class OnboardingModalComponent implements AfterViewChecked, OnDestroy, OnChanges {
  @Input() name!: string;
  @Input() showModal!: boolean;
  @ViewChild('maskRect') maskRectRef!: ElementRef;
  @ViewChild('maskModal') maskModalRef!: ElementRef;
  private resizeObserver!: ResizeObserver;
  target: string;
  image: string;
  title: string;
  desc: string;
  nextButton: string;
  previousButton: string;
  showStepper = true;
  onboardingCurrentStep = -1;
  position!: 'bottom' | 'right' | 'left';
  arrowPositionClass: 'up-arrow' | 'left-arrow' | 'right-arrow';

  constructor(
    private readonly store: Store,
    private readonly translate: TranslateService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges() {
    switch (this.name) {
      case 'ModalContentAndDesign':
        this.onboardingCurrentStep = -1;
        this.position = 'left';
        this.title = this.translate.instant('onboarding-modal.content-design.title');
        this.desc = this.translate.instant('onboarding-modal.content-design.desc');
        this.nextButton = this.translate.instant(
          'onboarding-modal.content-design.button',
        );
        this.previousButton = null;
        this.image = null;
        this.showStepper = false;
        this.target = '#app-configuration-submenu';
        break;
      case 'ModalTrackProgression':
        this.onboardingCurrentStep = 0;
        this.position = 'bottom';
        this.title = this.translate.instant('onboarding-modal.stepper.title');
        this.desc = this.translate.instant('onboarding-modal.stepper.desc');
        this.nextButton = this.translate.instant('onboarding-modal.stepper.button');
        this.previousButton = null;
        this.image = null;
        this.showStepper = false;
        this.target = '#stepper';
        break;
      case 'ModalGeneralConfiguration':
        this.onboardingCurrentStep = 1;
        this.position = 'right';
        this.title = this.translate.instant(
          'onboarding-modal.general-configuration.title',
        );
        this.desc = this.translate.instant('onboarding-modal.general-configuration.desc');
        this.nextButton = this.translate.instant('onboarding-modal.next');
        this.previousButton = this.translate.instant('onboarding-modal.previous');
        this.image = '/assets/onboarding-general-configuration.png';
        this.showStepper = true;
        this.target = '#generalMenu';
        break;
      case 'ModalMenuManagement':
        this.onboardingCurrentStep = 2;
        this.position = 'right';
        this.title = this.translate.instant('onboarding-modal.menu.title');
        this.desc = this.translate.instant('onboarding-modal.menu.desc');
        this.nextButton = this.translate.instant('onboarding-modal.next');
        this.previousButton = this.translate.instant('onboarding-modal.previous');
        this.image = '/assets/onboarding-menu.png';
        this.showStepper = true;
        this.target = '#menuMenu';
        break;
      case 'ModalPagesManagement':
        this.onboardingCurrentStep = 3;
        this.position = 'right';
        this.title = this.translate.instant('onboarding-modal.pages.title');
        this.desc = this.translate.instant('onboarding-modal.pages.desc');
        this.nextButton = this.translate.instant('onboarding-modal.next');
        this.previousButton = this.translate.instant('onboarding-modal.previous');
        this.image = '/assets/onboarding-pages.png';
        this.showStepper = true;
        this.target = '#pagesMenu';
        break;
      case 'ModalInterfaceCustomization':
        this.onboardingCurrentStep = 4;
        this.position = 'left';
        this.title = this.translate.instant('onboarding-modal.interface.title');
        this.desc = this.translate.instant('onboarding-modal.interface.desc');
        this.nextButton = this.translate.instant('onboarding-modal.interface.button');
        this.previousButton = this.translate.instant('onboarding-modal.previous');
        this.image = null;
        this.showStepper = true;
        this.target = '#interface';
        break;
      default:
        break;
    }
    this.arrowPositionClass =
      this.position === 'left'
        ? 'right-arrow'
        : this.position === 'right'
          ? 'left-arrow'
          : 'up-arrow';

    if (this.showModal) {
      this.store.dispatch(
        new SetEventProperties({
          name: this.name,
          component: 'Modal',
        }),
      );

      this.store.dispatch(new LogEvent('Modal Viewed'));
    }
  }

  ngAfterViewChecked() {
    this.updateMask();

    // Observer pour ajuster la taille du masque dynamiquement
    this.resizeObserver = new ResizeObserver(() => {
      this.updateMask();
    });

    if (this.target) {
      this.resizeObserver.observe(document.body);
    }
    this.cdr.detectChanges();
  }

  next(): void {
    this.store.dispatch(new SetOnboardingModal('', false));
    if (this.onboardingCurrentStep !== -1) {
      this.store.dispatch(new SetCurrentOnboardingStep(this.onboardingCurrentStep + 1));
    }
    this.store.dispatch(
      new SetEventProperties({
        name: 'ButtonNext' + this.name,
        component: 'Button',
      }),
    );
    this.store.dispatch(new LogEvent('Button Clicked'));
  }

  previous(): void {
    if (this.onboardingCurrentStep !== -1) {
      this.store.dispatch(new SetCurrentOnboardingStep(this.onboardingCurrentStep - 1));
    }
    this.store.dispatch(
      new SetEventProperties({
        name: 'ButtonPrevious' + this.name,
        component: 'Button',
      }),
    );
    this.store.dispatch(new LogEvent('Button Clicked'));
  }

  goToStep(step: number): void {
    this.store.dispatch(new SetCurrentOnboardingStep(step + 1));

    this.store.dispatch(
      new SetEventProperties({
        name: 'StepperSimulatorOnboarding',
        component: 'Stepper',
      }),
    );
    this.store.dispatch(new LogEvent('Stepper Clicked'));
  }

  onClose(): void {
    this.store.dispatch(new SetOnboardingModal('', false));

    if (this.onboardingCurrentStep !== -1) {
      this.store.dispatch(new SetCurrentOnboardingStep(-1));
    }
    this.store.dispatch(
      new SetEventProperties({
        name: 'ButtonClose' + this.name,
        component: 'Button',
      }),
    );
    this.store.dispatch(new LogEvent('Button Clicked'));
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  updateMask() {
    if (!this.target || !this.showModal) {
      return;
    }
    const targetRect = document.querySelector(this.target).getBoundingClientRect();
    const maskRect = this.maskRectRef.nativeElement;
    const maskModalRef = this.maskModalRef.nativeElement;

    // Ajuste la position et la taille du trou dans le masque
    maskRect.setAttribute('x', targetRect.left);
    maskRect.setAttribute('y', targetRect.top);
    maskRect.setAttribute('width', targetRect.width);
    maskRect.setAttribute('height', targetRect.height);

    if (this.position === 'left') {
      maskModalRef.setAttribute(
        'style',
        'right:' +
          (window.innerWidth - targetRect.left + 20) +
          'px; top:' +
          targetRect.y +
          'px',
      );
    } else if (this.position === 'right') {
      maskModalRef.setAttribute(
        'style',
        'left:' + (targetRect.right + 20) + 'px; top:' + targetRect.y + 'px',
      );
    } else if (this.position === 'bottom') {
      maskModalRef.setAttribute(
        'style',
        'top:' +
          (targetRect.bottom + 20) +
          'px; left:' +
          (targetRect.x + targetRect.width / 2 - 200) +
          'px',
      );
    }
  }
}
