<ng-container *ngIf="isLoading$ | async; else showContent">
  <div class="flex justify-center items-center w-full h-full">
    <mat-progress-spinner
      class="w-full h-full"
      mode="indeterminate"
      [strokeWidth]="5"
      [diameter]="50"
    ></mat-progress-spinner>
  </div>
</ng-container>

<ng-template #showContent>
  @if (showAppManagerMobileWarning$ | async) {
    <!-- mobile placeholder -->
    <rk-mobile-access-warning [isAppManager]="true"></rk-mobile-access-warning>
  } @else {
    <!-- desktop version -->
    @if (
      (!!(currentApp$ | async)?.appId &&
        (currentApp$ | async)?.androidNotificationIsAvailable) ||
      (isAdminOrSuperuser$ | async)
    ) {
      <!-- Container -->
      <div class="statistics-dashboard-container h-full flex w-full" [@fadeInOut]>
        <div class="flex-1 overflow-hidden">
          <!-- Welcome message -->
          <div class="p-0">
            <div class="text-[32px] font-bold">
              {{ 'dashboard.hello' | translate }} {{ currentFirstname$ | async }}
            </div>
            <div class="font-medium mb-8 opacity-70">
              {{ 'dashboard.welcome' | translate }}
            </div>
          </div>
          <!-- Empty state -->
          <div class="grid h-full">
            <div
              class="statistics-details pointer-events-auto z-10 col-start-1 row-start-1 items-center justify-center overflow-auto grid grid-flow-row"
              *ngIf="(totalSubscribers$ | async) === 0"
            >
              <div class="flex flex-col items-center w-full row-span-4">
                <img
                  class="max-w-[400px] pointer-events-none"
                  src="/assets/dashboard-preview.svg"
                />
                <h1 class="text-[24px] text-center font-bold max-w-[600px]">
                  {{
                    'notification-dashboard.empty-state.title' | translate: { count: 0 }
                  }}
                </h1>
                <p class="text-[16px] opacity-70 text-center max-w-[450px] mt-2">
                  {{ 'notification-dashboard.empty-state.desc' | translate }}
                </p>
              </div>
              <div class="flex flex-col items-center w-full row-span-1">
                <h1 class="text-[24px] text-center font-bold max-w-[600px]">
                  {{
                    'notification-dashboard.empty-state.tutorial.title'
                      | translate: { count: 0 }
                  }}
                </h1>
                <p class="text-[16px] opacity-70 text-center max-w-[450px]">
                  {{ 'notification-dashboard.empty-state.tutorial.desc' | translate }}
                </p>
                <div class="flex w-full gap-6 flex-row px-12 mb-2 mt-5">
                  <a
                    *ngFor="let item of [1, 2]"
                    [href]="
                      'notification-dashboard.empty-state.tutorial.card' + item + '.url'
                        | translate
                    "
                    target="_blank"
                    class="flex-1 tutorial-card"
                    (click)="clickEvent('LinkNotificationTutorial' + item)"
                  >
                    <div class="tutorial-title">
                      {{
                        'notification-dashboard.empty-state.tutorial.card' +
                          item +
                          '.title' | translate
                      }}
                    </div>
                    <div class="tutorial-desc line-clamp-3">
                      {{
                        'notification-dashboard.empty-state.tutorial.card' +
                          item +
                          '.desc' | translate
                      }}
                    </div>
                    <div class="font-bold tutorial-desc">
                      {{
                        'notification-dashboard.empty-state.tutorial.learn-more'
                          | translate
                      }}
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <!-- Stats details -->
            <div
              class="statistics-details rounded-xl bg-white p-8 flex-col w-full pb-0 z-0 col-start-1 row-start-1 overflow-auto"
            >
              <div
                class="flex-1 w-full mb-6"
                [class.blur-2xl]="(totalSubscribers$ | async) === 0"
              >
                <!-- Dashboard title -->
                <div class="text-xl font-bold mb-6">
                  {{ 'dashboard.title' | translate }}
                </div>
                <!-- Stats cards -->
                <div class="flex w-full gap-6 flex-row">
                  <!-- Total card -->
                  <div class="flex-1 stats-card">
                    <div class="stats-card-title">
                      {{ 'dashboard.subscribers-total' | translate }}
                    </div>
                    <div class="stats-big-number">
                      {{ totalSubscribers$ | async | number: '1.0-0' : currentLocale }}
                    </div>
                  </div>
                  <!-- Per platform card -->
                  <div class="flex-1 stats-card">
                    <div class="stats-card-title">
                      {{ 'dashboard.subscriber-detail' | translate }}
                    </div>
                    <div class="flex justify-between">
                      <!-- Per platform count -->
                      <div class="stats-big-number">
                        {{ iphoneCount$ | async | number: '1.0-0' : currentLocale }}
                      </div>
                      <div class="stats-big-number">
                        {{ androidCount$ | async | number: '1.0-0' : currentLocale }}
                      </div>
                    </div>
                    <!-- Per platform percentages -->
                    <div
                      class="flex justify-between items-center mb-1 text-black opacity-50 text-sm"
                    >
                      <div class="flex items-center gap-1">
                        <div>{{ iphonePercentage$ | async }}%</div>
                        <fa-icon [icon]="faApple"></fa-icon>
                      </div>
                      <div class="flex items-center gap-1">
                        <fa-icon [icon]="faAndroid"></fa-icon>
                        <div>{{ androidPercentage$ | async }}%</div>
                      </div>
                    </div>
                    <!-- Percentage bar -->
                    <div
                      *ngIf="(totalSubscribers$ | async) !== 0"
                      class="h-[24px] w-full flex platform-bar"
                    >
                      <div
                        *ngIf="(iphoneCount$ | async) !== 0"
                        [style.width.%]="iphonePercentage$ | async"
                        class="flex items-center p-2 platform-bar-apple"
                        [ngClass]="{
                          'unique-platform': (androidPercentage$ | async) === 0
                        }"
                      ></div>
                      <div
                        *ngIf="(androidCount$ | async) !== 0"
                        class="flex justify-end items-center p-2 platform-bar-android"
                        [style.width.%]="androidPercentage$ | async"
                        [ngClass]="{
                          'unique-platform': (iphonePercentage$ | async) === 0
                        }"
                      ></div>
                    </div>
                  </div>
                  <!-- Consumption card -->
                  <div class="flex-1 stats-card">
                    <div class="stats-card-title">
                      {{ 'dashboard.consumption' | translate }}
                    </div>
                    <div class="stats-big-number">
                      {{
                        (consumption$ | async)?.use_percent
                          | number: '1.0-2' : currentLocale
                      }}%
                    </div>
                    <div class="text-[#656565] font-medium mt-[15px] consumption-detail">
                      {{
                        (consumption$ | async)?.consumption
                          | number: '1.0-0' : currentLocale
                      }}
                      /
                      {{
                        (consumption$ | async)?.limit | number: '1.0-0' : currentLocale
                      }}
                      {{ 'menu.notifications' | translate }}
                    </div>
                    <div class="bg-[#DAEADF] flex w-full h-[9px] rounded-[10px]">
                      <div
                        [style.width.%]="(consumption$ | async)?.use_percent"
                        class="bg-valid rounded-[10px]"
                        [ngClass]="{
                          'bg-error': (consumption$ | async)?.use_percent >= 100
                        }"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Stats charts -->
              <div class="h-[650px]" [class.blur-2xl]="(totalSubscribers$ | async) === 0">
                <rk-data-chart
                  class="stats-card mb-6"
                  [dataStream$]="newSubscribers$"
                  [title]="'dashboard.new-subscribers' | translate"
                  [unit]="'subscriber'"
                ></rk-data-chart>
                <rk-data-chart
                  class="stats-card"
                  [dataStream$]="notificationsSent$"
                  [title]="'dashboard.notifications-sent' | translate"
                  [unit]="'notification'"
                  [lineColor]="'orangeGradient'"
                ></rk-data-chart>
              </div>
            </div>
          </div>
        </div>
        <!-- Notification sidebar -->
        <div class="hidden xxl:block">
          <rk-notification-sidebar></rk-notification-sidebar>
        </div>
      </div>
    } @else {
      <!-- Container -->
      <div class="statistics-dashboard-container h-full flex w-full" [@fadeInOut]>
        <div class="flex-1 h-full overflow-hidden">
          <!-- Welcome message -->
          <div>
            <div class="text-[32px] font-bold">
              {{ 'dashboard.hello' | translate }} {{ currentFirstname$ | async }}
            </div>
            <div class="font-medium mb-8 opacity-70">
              {{ 'dashboard.welcome' | translate }}
            </div>
          </div>
          <!-- Stats details -->
          <div
            class="statistics-details rounded-xl bg-white p-8 flex-col w-full h-full overflow-auto pb-0"
          >
            <div class="flex-1 w-full mb-6 m">
              <!-- Dashboard title -->
              <div class="text-xl font-bold mb-6">
                {{ 'dashboard.title' | translate }}
              </div>
              <!-- Upgrade Offer Placeholder -->
              <rk-upgrade-offer></rk-upgrade-offer>
            </div>
          </div>
        </div>
      </div>
    }
  }
  ‹
</ng-template>
