const tag = '[Onboarding]';

export class SetCurrentOnboardingStep {
  static readonly type = `${tag} set current onboarding step`;
  constructor(public currentStep: number) {}
}
export class SetOnboardingModal {
  static readonly type = `${tag} set modal`;
  constructor(
    public modalName: string,
    public showModal: boolean,
  ) {}
}

export class showOnboardingModal {
  static readonly type = `${tag} show modal`;
  constructor(public showModal: boolean) {}
}
