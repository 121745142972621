import { Component, Input } from '@angular/core';

@Component({
  selector: 'rk-step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss'],
})
export class StepIndicatorComponent {
  @Input() steps: number;
  @Input() activeStep = 0;
  @Input() onStepClick: (step: number) => void;

  arr() {
    return new Array(this.steps);
  }
}
