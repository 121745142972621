import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable, filter, take } from 'rxjs';
import { AppState } from './states/app.state';

export function getAppId$(store: Store): Observable<number> {
  return store.select(AppState.currentAppId).pipe(
    filter(app => !!app),
    take(1),
  );
}

export function getDeviceCategory() {
  const ua = navigator.userAgent;
  if (/mobile/i.test(ua)) {
    return 'Mobile';
  } else if (/tablet|ipad|playbook|silk/i.test(ua)) {
    return 'Tablet';
  } else if (/MSIE|Trident|Firefox|Safari|Chrome|Edge/i.test(ua)) {
    return 'Desktop';
  }

  return 'Other';
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const emailValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const email = control.value;
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const isValidEmail = emailPattern.test(email);

  return isValidEmail ? null : { invalidEmail: true };
};

export const phoneNumberValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const phoneNumber = control.value;
  const phonePattern = /^[0-9+]*$/;
  const isValidPhoneNumber = phonePattern.test(phoneNumber) && phoneNumber.length >= 8;

  return isValidPhoneNumber ? null : { invalidPhoneNumber: true };
};

export async function getMainColor(
  imageUrl: string,
  darkenFactor = 0.2,
): Promise<string> {
  return new Promise(resolve => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
      let totalR = 0;
      let totalG = 0;
      let totalB = 0;
      let pixelCount = 0;

      // sample pixels (every 5th pixel for performance)
      for (let i = 0; i < imageData.length; i += 20) {
        const r = imageData[i];
        const g = imageData[i + 1];
        const b = imageData[i + 2];

        // skip very light colors (close to white)
        if (r > 240 && g > 240 && b > 240) {
          continue;
        }

        totalR += r;
        totalG += g;
        totalB += b;
        pixelCount++;
      }

      // Calculate average color
      if (pixelCount === 0) {
        resolve('#6B7280'); // default medium gray if no valid pixels found
      } else {
        const avgR = Math.round(totalR / pixelCount);
        const avgG = Math.round(totalG / pixelCount);
        const avgB = Math.round(totalB / pixelCount);

        // Apply darkening factor
        const darkenedColor = darkenColor(avgR, avgG, avgB, darkenFactor);
        const color = rgbToHex(darkenedColor.r, darkenedColor.g, darkenedColor.b);

        resolve(color);
      }
    };

    img.src = imageUrl;
  });
}

function rgbToHex(r: number, g: number, b: number): string {
  return (
    '#' +
    [r, g, b]
      .map(x => {
        const hex = x.toString(16);

        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('')
  );
}

function darkenColor(
  r: number,
  g: number,
  b: number,
  factor: number,
): { r: number; g: number; b: number } {
  return {
    r: Math.max(0, Math.round(r * (1 - factor))),
    g: Math.max(0, Math.round(g * (1 - factor))),
    b: Math.max(0, Math.round(b * (1 - factor))),
  };
}
