export const tokenPath = 'auth.auth.token';
export const tokenRefreshToken = 'auth.auth.refreshToken';

export const SAVED_TO_LOCAL_STORAGE_NGXS = [
  tokenPath,
  tokenRefreshToken,
  'responsive',
  'onboarding.currentStep',
];

export const ASSETS_DIMENSIONS = [
  {
    width: 1242,
    height: 2208,
    name: 'splashscreen',
    source: 'rawSplashscreen',
    plan: [1, 2, 3, 4, 5, 6],
    isRequired: true,
  },
  {
    width: 1242,
    height: 2688,
    name: 'splashscreenLarge',
    source: 'rawSplashscreen',
    plan: [1, 2, 3, 4, 5, 6],
    isRequired: false,
  },
  {
    width: 3840,
    height: 2160,
    name: 'splashscreenTv',
    source: 'rawSplashscreen',
    plan: [2, 3, 6],
    isRequired: true,
  },
  {
    width: 4640,
    height: 1440,
    name: 'topShelfTv',
    source: 'rawSplashscreen',
    plan: [3, 6],
    isRequired: true,
  },
  {
    width: 1280,
    height: 768,
    name: 'iconTv',
    source: 'rawLogo',
    plan: [2, 3, 6],
    isRequired: true,
  },
  {
    width: 1024,
    height: 1024,
    name: 'logo',
    source: 'rawLogo',
    plan: [1, 2, 3, 4, 5, 6],
    isRequired: true,
  },
];
