<h1>{{ planName }}</h1>
<div class="price-line">
  <div class="price">
    <span class="price-value">{{ getPricing() }}</span>
    @if (billing === 'Monthly') {
      <span class="per-month" translate>plan.per-month</span>
    } @else {
      <span class="per-month" translate>plan.per-year</span>
    }
  </div>
</div>
<div class="monthly" *ngIf="billing === 'Annually'">
  <div class="line-through text-center text-lg">
    {{ getfullAnnualPricing() }}<span class="per-month" translate>plan.per-year</span>
  </div>
</div>
<div class="upgrade-button">
  <rk-button
    (buttonClick)="choosePlan()"
    [class]="recommended ? 'primary' : 'secondary'"
    [text]="'plan.choose' | translate"
    [fullWidth]="true"
  ></rk-button>
</div>
<div class="offer-details mt-5">
  <!-- BASIC -->
  <div class="limit" *ngIf="plan < 5">
    <div class="value" [innerHTML]="'plan.android' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan < 5">
    <div class="value" [innerHTML]="'plan.android-tablet' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan < 5">
    <div class="value" [innerHTML]="'plan.customizable' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan < 5">
    <div class="value" [innerHTML]="'plan.unlimited-tabs' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan < 5">
    <div class="value" [innerHTML]="'plan.unlimited-downloads' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan < 5">
    <div class="inline items-center justify-between">
      <span class="value" [innerHTML]="'plan.multi-stream' | translate"></span>
      <rk-help-icon
        class="inline-flex ml-1"
        rkTooltip
        [tooltipDescription]="'plan.multi-stream-tooltip' | translate"
      ></rk-help-icon>
    </div>
  </div>
  <div class="limit" *ngIf="plan < 5">
    <div class="inline items-center justify-between">
      <span class="value" [innerHTML]="'plan.multi-language' | translate"></span>
      <rk-help-icon
        class="inline-flex ml-1"
        rkTooltip
        [tooltipDescription]="'plan.multi-language-tooltip' | translate"
      ></rk-help-icon>
    </div>
  </div>
  <div class="limit" *ngIf="plan < 5">
    <span class="value" [innerHTML]="'plan.user-published' | translate"></span>
    <rk-help-icon
      class="inline-flex ml-1"
      rkTooltip
      [tooltipDescription]="
        'plan.user-published-tooltip' | translate: { currency: currencySymbol[currency] }
      "
    ></rk-help-icon>
  </div>
  <!-- SMART -->
  <div class="limit" *ngIf="plan === 5">
    <span class="value font-bold"> {{ 'plan.all-basic' | translate }} </span>
    <span
      [innerHTML]="'plan.plus' | translate"
      class="font-bold ml-[1px] text-[#29cf68]"
    ></span>
  </div>
  <div class="limit" *ngIf="plan === 5">
    <div class="value" [innerHTML]="'plan.android-and-ios' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan === 5">
    <div class="value" [innerHTML]="'plan.tablet' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan === 5">
    <div class="inline items-center justify-between">
      <span
        class="value"
        [innerHTML]="'plan.push' | translate: { number: pushNotifications }"
      ></span>
      <rk-help-icon
        class="inline-flex ml-1"
        rkTooltip
        [tooltipDescription]="
          'plan.push-tooltip' | translate: { currency: currencySymbol[currency] }
        "
      ></rk-help-icon>
    </div>
  </div>
  <div class="limit" *ngIf="plan === 5">
    <div class="inline items-center justify-between">
      <span class="value" [innerHTML]="'plan.white-label' | translate"></span>
      <rk-help-icon
        class="inline-flex ml-1"
        rkTooltip
        [tooltipDescription]="'plan.white-label-tooltip' | translate"
      ></rk-help-icon>
    </div>
  </div>
  <!-- PREMIUM -->
  <div class="limit" *ngIf="plan > 5">
    <span class="value font-bold"> {{ 'plan.all-smart' | translate }} </span>
    <span [innerHTML]="'plan.plus' | translate" class="font-bold ml-[1px] text-[#29cf68]"></span>
  </div>
  <div class="limit" *ngIf="plan > 5">
    <div class="inline items-center justify-between">
      <span class="value" [innerHTML]="'plan.rk-published' | translate"></span>
      <rk-help-icon
        class="inline-flex ml-1"
        rkTooltip
        [tooltipDescription]="'plan.publication-tooltip' | translate"
      ></rk-help-icon>
    </div>
  </div>
  <div class="limit" *ngIf="plan > 5">
    <div class="inline items-center justify-between">
      <span
        class="value"
        [innerHTML]="'plan.push' | translate: { number: pushNotifications }"
      ></span>
      <rk-help-icon
        class="inline-flex ml-1"
        rkTooltip
        [tooltipDescription]="
          'plan.push-tooltip' | translate: { currency: currencySymbol[currency] }
        "
      ></rk-help-icon>
    </div>
  </div>
  <div class="limit" *ngIf="plan > 5">
    <div class="inline items-center justify-between">
      <span class="value" [innerHTML]="'plan.auto' | translate"></span>
      <rk-help-icon
        class="inline-flex ml-1"
        rkTooltip
        [tooltipDescription]="'plan.carplay-tooltip' | translate"
      ></rk-help-icon>
    </div>
  </div>
  <div class="limit" *ngIf="plan > 5">
    <div class="value" [innerHTML]="'plan.tv' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan > 5">
    <div class="value" [innerHTML]="'plan.apple-watch' | translate"></div>
  </div>
  <div class="limit" *ngIf="plan > 5">
    <div class="inline items-center justify-between">
      <span class="value" [innerHTML]="'plan.monetization' | translate"></span>
      <rk-help-icon
        class="inline-flex ml-1"
        rkTooltip
        [tooltipDescription]="'plan.monetization-tooltip' | translate"
      ></rk-help-icon>
    </div>
  </div>
</div>
